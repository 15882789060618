export const deckData = `
Umbrella Adventure Red Line
Exhibition Ship Apple Common
Air Police Double Silence
Jelly Computer Demon Revolver
Guide Love Memory Down
Nest Bubble Poetry Sweet
Evening Plank Oasis Cereal
Science Cross Hot Dwarf
Color Rifle Post Boss
Humor Garbage Car Small
Climbing Pond Firefighter Sheep
Walk Chick Fountain Ice
Bottom Leaf Career Swing
Farm Helmet Strength Couch
Middle Grape Shower Utensil

Wine Boat Iron Whirlwind
Plush Archaeology Cork Distributor
Pig Sage Trade Rail
Track Milk Taste Hell
End Commerce Trophy Shovel
Chain Cold Advocate Fur
Hair Tool Hall Heroine
Exit Plant Candle Antiquity
Uniform Whip Human Window
Roll Hook Fuel Luck
Ammo Vacation Rose Volume
Salt Cotton Chef Square
Mouth Blade Peace Bowl
Food Gift Library Bomb
Degree Siren Apron Cube
Wind China Belt Bark
Full Branch Quilt Soap
Envelop Turnip Brother Grate
Donkey Virgin Mug Park
Palace Wing Snow Scissors
Skull Award Fly Calendar
Soldier Ghost Storm Pad
Hearth Stage Hearing Ladle
Series Glass Turn Hobbies
Lighthouse Hero Emergency Metal
Tree School Ball Night
Forbidden Hammer Drawing Bin
Statue Grain Shed Turtle
Castle Frost Plastic Detergent
Armor Curtain Charge Antarctica
Edge Beer Marker Technology
Match Mother Rope Paradise

Angel Key Bed Zoo
Outlet Size Camp Crescent
Young Ocean Camouflage Salad
Gate Ray Boat Rat
Honey Desert Paper Virus
Jungle Frame Weight Pumpkin
Essence Joke Hospital Roast
Dessert God Bank Lava
Life Heavy Tea Room
Raft Suspect Mobile Oak
Cream Laser Arm Work
Slow Bay Pipe Magic
Newspaper Bus Egg Nail
Pie Cage Bump Green
Tongue Wizard Attraction Rake
Soup Game Canvas Punch
Black Hunt Thread Sponge
France Physical Rabbit Fault
Lemon Dog Ramp Television
Shelter Ireland Medusa Lighter
Bouquet Box Eye Sea
Limit Pirate Intersection Instrument
Forest Display Octopus Right
Mask Sugar Port Gamble
Ring Cane Wool Dungeon
Volcano Root Screen Beast
Explore Giant Bucket Barn
Country Secret Cigarette Stick
Performance Mass Photo Orchard
Lady Blanket Pearl Carrot
Genius Jewel Summit Bug
Bread Chess Mummy Flight
Soft Poison Diamond Book
Radar Reptile Major Dead
Stud Camera Bench Patron
Religion Promotion Thunder Straw
Bag Thin Rice Balcony
Cookie Craft Shell Flea
Button Union Axe Board
Sleeve Architecture Family Flame
Bulb Cake Princess Cactus
Balance Universe Chameleon Wheel
Story Jar Chimney Clean
Cinema Cup Jump Dream
Rooster Alcohol Nude Well
Sound Gold Melon Lake
History Day Shield Inspection
Fire Sister Hotel Drum


Fool Animal Map Waiter
Spicy Hat Large Pine
Tube Berry Electricity Spy
Skin Border Log Master
Carpet Laugh Luxury Battery
Tile Bite Fashion Movie
Earth Mouse Yellow Toilet
Stamp Bang Owl Ruin
Violin Fairy Cuddle New
File Magnet Tennis Shop
Shark Feed Ear Cavern
Glove Shape Ground Asia
Cabin Old Tiger Alliance
Queen Water Attic Dough
Bell Fruit Whale Wild
America Wand Pigeon Robot
Airport Sport Currency Mirage
Wish Buckle Sword Grease
Note Hard Kitchen Finger
Defence Piano Sense Bridge
Tunnel Dress Vision Blue
Hardware Ticket Witness Sausage


Baby Sight Base Subway
Body Cat Record Monster
Place Glasses Syrup King
Stunt Month Stroke Cabbage
Field Flute Crab Passion
Fast Light Case Pepper
Microscope Report String Blood
Permanent Cannon Potato Champion
Alarm Space Accessory Brain
Train Bear Solitary Grave
Twins Patio Africa Liquid
Spear Ink Stitches Garden
Cockroach Landscape Champagne Switzerland
Charm Tame Flour Sail
Needle Knot Butcher Smoke
Snail Dynamite Pliers Slide
Dish Myth Big Business
Cantina Lightning Phone Music
Mushroom Truck Church Table
Star Handicap Lion Rich
Fair Dirty Medal Wave
Candy Court Danger Grass
Lamp House Hood Pear
Spice Detective Town Sky
Motor Heel Revenge Watch
Cloud Cheese Illness Column
Wood Skeleton Hole March
Knight Toy Basket Weak
Delivery Song Station Throat
Claw Mud Grenade Trash


Shirt Tail Menu Child
Recent Layer Devil Mint
Snake Belgium Sharp Carton
Fable Cavalry Minor White
Internet Snack Captain Father
Thief Theatre Smell Oar
Travel Flower Fishing Brush
Device Beach Morning Head
Doe Clothing Banana Tattoo
Loop Stylist Pyramid Club
Challenge Friend Bill Tank
Bedroom Mustard Apprentice Heritage
Capital Doll Past Flash
Tradition Bronze Saber Broom
District Lazy Stocks Crane
Suite Missile Comedy Pocket
Painting Speaker Ladybug Restaurant
Tobacco Clown Feline Garage
Entry Round Sand Oven
Beard North Bottle Group
Pump Season Card Porcelain
Studio Cave Mosquito Magician
Marine Spring Band Bone
Tent Noodle Weapon Bakery
Recipient Cell Console Voice


Brown Covered Furniture Comforter
Nut Bath Nerve Plane
First Fever Building Boxing
Flask Marriage Target Leak
Mirror Ballroom Hen Bike
Shadow Picture Market Arrow
Sun Concert Cable Basement
Ladder Pilot Skate Circus
Polar Short Drink Horse
Cow Circle Sock Door
Pizza Moustache Surgeon Silver
Office Pair Protect Chocolate
Dinosaur Birthday Press Military
Thermometer Lock Schedule Guitar
Cocktail Face Butterfly Road


Nurse Sticky Bird Treasure
Hide Casino Mind Moon
Limb Napkin Perfume Chariot
Stone Coffee Puzzle Cougar
Prison Matter Crown Dance
Suit Summer Species Temple
Weather Order Elastic Triangle
Pan Orange Tiny Feather
Judge Herbs Dresser Suitcase
Caterpillar Bar Strong Knife
Talent Vegetable Foam Handle
Transport Shelf Tornado Spider
Recipe Blond Trailer Decor
Engine Letter Safari Sewer
Powder Labyrinth Party Assassin



Oil Vampire Cushion Necklace
Dictator Ambulance Fox Mountain
Island High Foot Winter
Crow Hand River Radio
Mail Revolution Fry Ruler
Deep Region Cemetery Bracelet
Duck Trim Barracks Kiss
Fish Padlock Inside Astronaut
Sunday Wolf Guardian Moist
Tomato Grey Forecast Screw
Doctor Mark Chair Beef
Gas Appetite War Heart
Roof Canal Wheat Museum
Autumn Machine Curry Underground
Chest Pit Paintbrush Nightmare
Victory Dragon Europe Repair
Asset Race Closet Rain
Top Show Diet Explosion

`;
